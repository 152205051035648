import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Button,
  Form,
  Input,
  Pagination,
  Popconfirm,
  Select,
  Spin,
  Table,
} from "antd";
import { Col, Row } from "react-bootstrap";
import { ReloadOutlined } from "@ant-design/icons";
import _ from "lodash";
import {
  deleteUser,
  getAllPatientListData,
  setIsPatientDeleted,
} from "redux/reducers/PatientList/patientList.slice";
import { useDispatch, useSelector } from "react-redux";
import {
  getLocationData,
  getPermissionLocationWise,
  setSelectedLocation,
} from "redux/reducers/Role/role.slice";
import TrashIcon from "../../../Img/trash.svg";
import EditIcon from "../../../Img/edit.svg";
import {
  clearData,
  setPatientEdit,
} from "redux/reducers/SearchPanel/globalSearch.slice";
import { sendDataToSocket } from "socket/SocketComponent";
import { useNavigate } from "react-router-dom";
import {
  setIvfIdListData,
  setSelectedPatient,
} from "redux/reducers/common.slice";

const searchDataInitialState = {
  patient_name: "",
  location_id: null,
  type_of_patient: null,
  type_of_treatment: null,
};

const PatientList = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const [searchData, setSearchData] = useState(searchDataInitialState);
  const [currentPage, setCurrentPage] = useState(1);

  const { patientListDetails, patientListLoading, isPatientDeleted } =
    useSelector(({ patientList }) => patientList);
  const { locationData, isLocationLoading } = useSelector(({ role }) => role);

  const dispatch = useDispatch();

  const pageSize = 10;

  const typeOfPatient = [
    { value: "", label: "All" },
    { value: 1, label: "Married Couple" },
    { value: 2, label: "Single Woman" },
    { value: 3, label: "Donor (Male)" },
    { value: 4, label: "Donor (Female)" },
    { value: 5, label: "Surrogate Mother" },
  ];

  const typeOfTreatmentOptions = [
    { value: "", label: "All" },
    { value: 1, label: "IVF" },
    { value: 2, label: "IUI" },
    { value: 3, label: "NP" },
  ];

  const columns = [
    {
      title: "Sr No",
      key: "sno",
      render: (text, data, index) => {
        const startIndex = (currentPage - 1) * pageSize;
        return data?.patient_id ? startIndex + index + 1 : "";
      },
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (text, data) =>
        data?.patient_id && !data?.deleted ? (
          <ul className="d-flex align-items-center user_roll">
            <li className="btn_delete btn_edit">
              <Button
                className="btn_transparent"
                onClick={() => {
                  dispatch(setPatientEdit(data));

                  localStorage.setItem(
                    "userLocation",
                    window.btoa(JSON.stringify(data.location_id || null))
                  );
                  sendDataToSocket("JUR", {
                    room_id: data.location_id || null,
                  });
                  dispatch(setSelectedLocation(data.location_id || null));
                  dispatch(getPermissionLocationWise(data.location_id || null));
                }}
              >
                <img src={EditIcon} alt="" />
              </Button>
            </li>
            <li className="btn_delete">
              <Popconfirm
                title="Delete Patient"
                description="Are you sure to delete this patient?"
                onConfirm={() => handleDeleteToggle(data)}
                okText="Yes"
                cancelText="No"
              >
                <Button className="btn_transparent">
                  <img src={TrashIcon} alt="" />
                </Button>
              </Popconfirm>
            </li>
          </ul>
        ) : (
          <>{""}</>
        ),
    },
    {
      title: "Patient Name",
      dataIndex: "patient_full_name",
      key: "patient_full_name",
      render: (text, row) => (
        <span>{row?.patient_full_name ? row?.patient_full_name : "-"}</span>
      ),
    },

    {
      title: "Partner Name",
      dataIndex: "partner_full_name",
      key: "partner_full_name",
      render: (text, row) => (
        <span>{row?.partner_full_name ? row?.partner_full_name : "-"}</span>
      ),
    },

    {
      title: "Type of Treatment",
      dataIndex: "type_of_treatment",
      key: "type_of_treatment",
    },

    {
      title: "Type of Patient",
      dataIndex: "type_of_patient",
      key: "type_of_patient",
    },

    {
      title: "Location",
      dataIndex: "location_name",
      key: "location_name",
    },
    {
      title: "Deleted",
      dataIndex: "deleted",
      key: "deleted",
      render: (text, row) => (
        <span style={{ color: row?.deleted ? "#ff6363" : "#7ecb89" }}>
          {row?.deleted ? "Yes" : "No"}
        </span>
      ),
    },
  ];

  useEffect(() => {
    dispatch(
      getAllPatientListData({
        start: currentPage,
        limit: pageSize,
        payload: searchData,
      })
    );

    if (!locationData) {
      dispatch(getLocationData());
    }
  }, []);

  const handleFilters = useCallback(
    (e, currentPage, searchData) => {
      setCurrentPage(1);
      dispatch(
        getAllPatientListData({
          start: 1,
          limit: pageSize,
          payload: searchData,
        })
      );
    },
    [dispatch]
  );
  useEffect(() => {
    if (isPatientDeleted) {
      dispatch(
        getAllPatientListData({
          start: currentPage,
          limit: pageSize,
          searchData,
        })
      );
      dispatch(setIsPatientDeleted(false));
    }
  }, [isPatientDeleted]);

  const handleDeleteToggle = useCallback(async (item) => {
    dispatch(
      deleteUser({
        patient_reg_id: item?._id,
      })
    );

    navigate("/patient-registration");
    dispatch(setSelectedPatient({}));
    dispatch(setIvfIdListData([]));
    dispatch(clearData());
  }, []);
  const debouncehandleSearchInput = useCallback(
    _.debounce(handleFilters, 800),
    []
  );

  const allPatientList = useMemo(
    () =>
      patientListDetails?.patient_list?.length
        ? patientListDetails?.patient_list
        : [],
    [patientListDetails]
  );

  const totalPatients = useMemo(
    () => patientListDetails?.total_patient || 0,
    [patientListDetails]
  );

  const locationOptions = useMemo(
    () =>
      locationData.map((item) => ({
        label: item.location_name,
        value: item.location_id,
      })) || [],
    [locationData]
  );

  const handlePageChange = useCallback(
    (value) => {
      setCurrentPage(value);
      dispatch(
        getAllPatientListData({
          start: value,
          limit: pageSize,
          payload: searchData,
        })
      );
    },
    [dispatch, searchData]
  );

  return (
    <div className="page_main_content">
      <div className="page_inner_content">
        {(patientListLoading || isLocationLoading) && (
          <Spin tip="Loading" size="large">
            <div className="content" />
          </Spin>
        )}
        <div className="page_header">
          <Row className="align-items-center">
            <Col xxl={12}>
              <div className="page_header_right">
                <Form
                  name="basic"
                  initialValues={{
                    remember: true,
                  }}
                  layout="vertical"
                  form={form}
                  scrollToFirstError
                  autoComplete="off"
                >
                  <ul className="justify-content-start">
                    <li className="w_250 w_xxs_100 d-inline-block px-2">
                      <Form.Item
                        label=""
                        name="type_of_patient"
                        className="custom_select mb-0"
                      >
                        <Select
                          placeholder="Type Of Patient"
                          name="type_of_patient"
                          value={searchData?.type_of_patient}
                          onChange={(value) => {
                            debouncehandleSearchInput(value, currentPage, {
                              ...searchData,
                              type_of_patient: !!value ? value : null,
                            });
                            setSearchData({
                              ...searchData,
                              type_of_patient: !!value ? value : null,
                            });
                          }}
                          options={typeOfPatient}
                        />
                      </Form.Item>
                    </li>
                    <li className="w_250 w_xxs_100 d-inline-block px-2">
                      <Form.Item
                        label=""
                        name="type_of_treatment"
                        className="custom_select mb-0"
                      >
                        <Select
                          placeholder="Type Of Treatment"
                          name="type_of_treatment"
                          value={searchData?.type_of_treatment}
                          onChange={(value) => {
                            debouncehandleSearchInput(value, currentPage, {
                              ...searchData,
                              type_of_treatment: !!value ? value : null,
                            });
                            setSearchData({
                              ...searchData,
                              type_of_treatment: !!value ? value : null,
                            });
                          }}
                          options={typeOfTreatmentOptions}
                        />
                      </Form.Item>
                    </li>
                    <li className="w_250 w_xxs_100 d-inline-block px-2">
                      <Form.Item
                        label=""
                        name="location_id"
                        className="custom_select mb-0"
                      >
                        <Select
                          placeholder="Select Location"
                          name="location_id"
                          value={searchData?.location_id}
                          onChange={(value) => {
                            debouncehandleSearchInput(value, currentPage, {
                              ...searchData,
                              location_id: !!value ? value : null,
                            });
                            setSearchData({
                              ...searchData,
                              location_id: !!value ? value : null,
                            });
                          }}
                          options={[
                            { label: "All", value: "" },
                            ...locationOptions,
                          ]}
                        />
                      </Form.Item>
                    </li>
                    <li className="search_wrap w_250 w_xxs_100 d-inline-block px-2">
                      <div className="search_top">
                        <Input
                          placeholder="Search anything here"
                          name="patient_name"
                          value={searchData?.patient_name}
                          onChange={(e) => {
                            debouncehandleSearchInput(e, currentPage, {
                              ...searchData,
                              patient_name: e.target.value || "",
                            });
                            setSearchData({
                              ...searchData,
                              patient_name: e.target.value || "",
                            });
                          }}
                        />
                      </div>
                    </li>
                    <li className="align-self-center d-inline-block px-2">
                      <div className="plus_mobile_wrap">
                        <Button
                          className="btn_primary"
                          onClick={() => {
                            if (searchData !== searchDataInitialState) {
                              handleFilters(
                                null,
                                currentPage,
                                searchDataInitialState
                              );
                              setSearchData(searchDataInitialState);
                              form.setFieldsValue(searchDataInitialState);
                            }
                          }}
                        >
                          <ReloadOutlined className="me-2" />
                          <span className="ms-0">Reset Filter</span>
                        </Button>
                      </div>
                    </li>
                  </ul>
                </Form>
              </div>
            </Col>
          </Row>
        </div>
        <div className="cmn_table_wrap all_lead_table_wrap">
          <div className="user_main_wrap">
            <div className="user_box">
              <Table
                columns={columns}
                dataSource={allPatientList}
                pagination={false}
              />
            </div>
          </div>
          <Pagination
            current={currentPage}
            total={totalPatients}
            pageSize={pageSize}
            onChange={handlePageChange}
            className="patient_list_pagination"
          />
        </div>
      </div>
    </div>
  );
};

export default PatientList;
