import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";

const initialState = {
  patientObject: {},
  loader: false,
  phaseCount: {},
  scheduleObject: {},
  scheduleLoader: false,
};
export const getPhseWisePatient = createAsyncThunk(
  "admin/get-patient-list-phase-wise",
  (props, { dispatch }) => {
    const { start, limit, phase, location_id } = props;

    return new Promise((resolve, reject) => {
      axios
        .post(
          `dashboard/patient-list/${location_id}/${start}/${limit}${
            phase ? `?phase=${phase}` : ""
          }`
        )
        .then((res) => {
          if (res.data.err === 0) {
            if (res.data.data.dashboardPatientList.length > 0)
              resolve(res.data.data);
            else resolve([]);
          } else reject([]);
        })
        .catch((error) => {
          toast.error(error.response.data.msg);
          reject(error);
        });
    });
  }
);

export const getPhaseCount = createAsyncThunk(
  "admin/get-count-list",
  (location_id, { dispatch }) => {
    return new Promise((resolve, reject) => {
      axios
        .get(`dashboard/count/${location_id}`)
        .then((res) => {
          if (res.data.err === 0) {
            if (Object.keys(res.data.data).length > 0) resolve(res.data.data);
            else resolve([]);
          } else reject([]);
        })
        .catch((error) => {
          toast.error(error.response.data.msg);
          reject(error);
        });
    });
  }
);

export const getScheduleList = createAsyncThunk(
  "admin/get-schedule-list",
  (props, { dispatch }) => {
    const { start, limit, location_id, date, prevData } = props;

    return new Promise((resolve, reject) => {
      axios
        .post(`dashboard/today-schedule/${location_id}/${start}/${limit}`, {
          location_id,
          schedule_date: date,
        })
        .then((res) => {
          if (res.data.err === 0) {
            if (Object.keys(res.data.data.scheduleList).length > 0) {
              let data = res.data.data;
              if (prevData && Object.keys(prevData).length) {
                data = {
                  ...data,
                  scheduleList: [
                    ...prevData.scheduleList,
                    ...res.data.data.scheduleList,
                  ],
                };
              }
              resolve(data);
            } else resolve([]);
          } else reject([]);
        })
        .catch((error) => {
          toast.error(error.response.data.msg);
          reject(error);
        });
    });
  }
);

export const roleSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {},
  extraReducers: {
    [getPhseWisePatient.pending]: (state) => {
      state.loader = true;
    },
    [getPhseWisePatient.rejected]: (state) => {
      state.loader = false;
    },
    [getPhseWisePatient.fulfilled]: (state, action) => {
      state.patientObject = action.payload;
      state.loader = false;
    },
    [getPhaseCount.pending]: (state) => {
      state.phaseCount = {};
      state.loader = true;
    },
    [getPhaseCount.rejected]: (state) => {
      state.phaseCount = {};
      state.loader = false;
    },
    [getPhaseCount.fulfilled]: (state, action) => {
      state.phaseCount = action.payload;
      state.loader = false;
    },
    [getScheduleList.pending]: (state) => {
      state.scheduleLoader = true;
    },
    [getScheduleList.rejected]: (state) => {
      state.scheduleLoader = false;
    },
    [getScheduleList.fulfilled]: (state, action) => {
      state.scheduleObject = action.payload;
      state.scheduleLoader = false;
    },
  },
});

export default roleSlice.reducer;
