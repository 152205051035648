import { Form, Input } from "antd";
import { memo } from "react";

const EmbryoTableInput = ({
  label,
  name,
  disabled = false,
  placeholder,
  value,
  onBlur,
  showInput = true,
  ...props
}) => {
  if (!showInput) return null;
  return (
    <Form.Item label={label} name={name} className="mb-0" {...props}>
      <Input
        placeholder={placeholder}
        value={value}
        onBlur={onBlur}
        disabled={disabled}
        name={name}
      />
    </Form.Item>
  );
};

export default memo(EmbryoTableInput);
