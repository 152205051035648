import { Pagination, Select, Table } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { setPatientEdit } from "redux/reducers/SearchPanel/globalSearch.slice";
import { sendDataToSocket } from "socket/SocketComponent";
import {
  getPermissionLocationWise,
  setSelectedLocation,
} from "redux/reducers/Role/role.slice";
import { getPhseWisePatient } from "redux/reducers/Dashboard/dashboard.slice";
import { useEffect, useState } from "react";

export default function PhasePatientList({
  selectedPhase,
  fetchPhase,
  startPage,
}) {
  let UserPreferences = localStorage.getItem("UserPreferences");
  if (UserPreferences) {
    UserPreferences = JSON.parse(window.atob(UserPreferences));
  }
  const { patientObject, loader } = useSelector(({ dashboard }) => dashboard);
  const dispatch = useDispatch();
  const { selectedLocation } = useSelector(({ role }) => role);
  const [filterOption, setFilterOption] = useState(null);
  useEffect(() => {
    setFilterOption(null);
  }, [selectedPhase]);
  let columns = [
    {
      title: "Patient Name",
      dataIndex: "patient_full_name",
      key: "patient_full_name",
      render: (text, data) => {
        return data?.patient_full_name ?? "-";
      },
    },
    {
      title: "Patient Id",
      dataIndex: "patient_id",
      key: "patient_id",
      render: (text, data) => {
        return data?.patient_id ?? "-";
      },
    },
    {
      title: "Phase",
      dataIndex: "phase",
      key: "phase",
      render: (text, data) => {
        if (+data?.phase === 4) return "Completed";

        return data?.phase ? `${data?.phase}` : "-";
      },
    },
  ];
  if (!UserPreferences?.other) {
    columns.push({
      title: "Location",
      dataIndex: "location_name",
      key: "location_name",
      render: (text, data) => {
        return data?.location_name ?? "-";
      },
    });
  }
  const filterPhase = (e) => {
    let phase;
    if (e === "5") phase = "";
    else phase = e;
    setFilterOption(e);
    dispatch(
      getPhseWisePatient({
        location_id: selectedLocation,
        phase: phase,
        start: 1,
        limit: 10,
      })
    );
  };
  return (
    <div className="home_patient_list">
      <div className="home_patient_list_title pb-md-3 pb-2 d-flex justify-content-between align-items-center">
        <h3 className="p-0 m-0">
          {(+filterOption || +selectedPhase) === 4
            ? "Completed Patient"
            : (+filterOption || +selectedPhase) === 5
              ? "All Patient"
              : `Phase ${filterOption || selectedPhase} Patient`}
        </h3>
        <div className="custom_select">
          <Select
            showSearch
            allowClear={true}
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            placeholder="Select"
            value={filterOption}
            options={[
              { value: "1", label: "Phase 1" },
              { value: "2", label: "Phase 2" },
              { value: "3", label: "Phase 3" },
              { value: "4", label: "Completed" },
              { value: "5", label: "All" },
            ]}
            onChange={filterPhase}
            name="embryo_days"
          />
        </div>
      </div>
      <div className="cmn_table_wrap">
        {loader ? (
          <div className="loading_table home_table_table">
            <p>Loading...</p>
          </div>
        ) :
          <>

            <Table
              columns={columns}
              dataSource={patientObject?.dashboardPatientList}
              pagination={false}
              className="home_table_table"
              onRow={(record, rowIndex) => {
                return {
                  onClick: (event) => {
                    dispatch(setPatientEdit(record));
                    localStorage.setItem(
                      "userLocation",
                      window.btoa(JSON.stringify(record.location_id || null))
                    );
                    sendDataToSocket("JUR", {
                      room_id: record.location_id || null,
                    });
                    dispatch(setSelectedLocation(record.location_id || null));
                    dispatch(getPermissionLocationWise(record.location_id || null));
                  },
                };
              }}
            />
            <Pagination
              current={startPage}
              total={patientObject?.dashboardPatientListCount}
              pageSize={10}
              className="patient_list_pagination"
              onChange={fetchPhase}
            />

          </>
        }
      </div>
    </div>
  );
}
