import { Col, Row } from "react-bootstrap";
import { Spin } from "antd";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Calendar from "react-calendar";
import PhaseCount from "./Dashboard/PhaseCount";
import {
  getPhseWisePatient,
  getScheduleList,
} from "redux/reducers/Dashboard/dashboard.slice";
import PhasePatientList from "./Dashboard/PhasePatientList";
import moment from "moment";
import ScheduleList from "./Dashboard/ScheduleList";
const Dashboard = () => {
  const dispatch = useDispatch();
  const { selectedLocation } = useSelector(({ role }) => role);
  const [dateState, setDateState] = useState(new moment());
  const { scheduleObject, loader } = useSelector(({ dashboard }) => dashboard);
  const [selectedPhase, setSelectedPhase] = useState(1);
  const [startPage, setStartPage] = useState(1);
  const [scheduleStartPage, setScheduleStartPage] = useState(1);
  const ref = useRef();

  useEffect(() => {
    if (selectedLocation) {
      fetchPhase(selectedPhase, startPage);
    }
  }, [selectedLocation]);

  useEffect(() => {
    if (selectedLocation && scheduleStartPage) {
      dispatch(
        getScheduleList({
          start: scheduleStartPage,
          limit: 10,
          location_id: selectedLocation,
          date: moment(dateState).format("YYYY-MM-DD"),
          prevData: scheduleObject,
        })
      );
    }
  }, [selectedLocation, scheduleStartPage]);

  const changeDate = (e) => {
    setDateState(new Date(e));
    dispatch(
      getScheduleList({
        start: 1,
        limit: 10,
        location_id: selectedLocation,
        date: moment(e).format("YYYY-MM-DD"),
      })
    );
  };

  const fetchPhase = (phase, start) => {
    dispatch(
      getPhseWisePatient({
        location_id: selectedLocation,
        phase: phase ?? selectedPhase,
        start: start ?? startPage,
        limit: 10,
      })
    );
  };

  const onScroll = () => {
    if (ref.current) {
      const { scrollTop, scrollHeight, clientHeight } = ref.current;
      if (
        scheduleObject?.scheduleList?.length !==
        scheduleObject?.scheduleListCount
      ) {
        if (scrollTop + clientHeight === scrollHeight)
          setScheduleStartPage(scheduleStartPage + 1);
      }
    }
  };

  return (
    <div className="page_main_content">
      <div className="page_inner_content border-0 p-0">
        <PhaseCount
          setSelectedPhase={setSelectedPhase}
          selectedPhase={selectedPhase}
          setStartPage={setStartPage}
        />
        <div className="home_list_area">
          <Row className="m-0">
            <Col lg={6} className="patient_list">
              <PhasePatientList
                selectedPhase={selectedPhase}
                fetchPhase={(value) => {
                  fetchPhase(selectedPhase, value);
                  setStartPage(value);
                }}
                startPage={startPage}
              />
            </Col>
            <Col lg={6} className="schedule">
              <div className="home_calendar_title">
                <h3 className="m-0">Today’s Schedule</h3>
              </div>
              <div className="d-xxl-flex d-lg-block d-md-flex schedule_partition">
                <Col xxl={6} lg={12} md={6} className="home_calendar">
                  <Calendar
                    value={dateState}
                    onChange={changeDate}
                    className="date_calendar"
                  />
                </Col>
                <Col
                  xxl={6}
                  lg={12}
                  md={6}
                  className="user_list"
                  ref={ref}
                  onScroll={onScroll}
                >
                  <ScheduleList />
                </Col>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
