import { ArrowRightOutlined } from "@ant-design/icons";
import { memo, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  getPhaseCount,
  getPhseWisePatient,
} from "redux/reducers/Dashboard/dashboard.slice";

let phsaseClass = {
  1: "orange-arrow",
  2: "green-arrow",
  3: "blue-arrow",
  4: "purple-arrow",
};
const Card = memo(
  ({
    count,
    percent,
    thisWeekCount,
    phase,
    setSelectedPhase,
    selectedPhase,
    setStartPage,
  }) => {
    const dispatch = useDispatch();
    const { selectedLocation } = useSelector(({ role }) => role);

    const phaseClick = () => {
      dispatch(
        getPhseWisePatient({
          location_id: selectedLocation,
          phase: phase,
          start: 1,
          limit: 10,
        })
      );
      setSelectedPhase(phase);
      setStartPage(1);
    };
    return (
      <Col lg={3} md={6} className="home_card_item">
        <div
          className="card_item d-flex align-items-center"
          onClick={selectedPhase === phase ? null : phaseClick}
        >
          <div className="card_number">
            <h2 className="m-0">{count}</h2>
          </div>
          <div className="card_description">
            <p className="m-0 main_title">
              {phase === 4 ? "Completed patient" : `Phase ${phase} patient`}
            </p>
            <p className="m-0 sub_title">
              {percent ? Math.abs(percent) : thisWeekCount}
              {`${percent ? "% " : " "}`}
              {percent
                ? `${percent > 0 ? "Increase" : "Decrease"} from last week`
                : "Patient in this week"}
            </p>
          </div>
          <div className="right_arrow_image">
            <ArrowRightOutlined className={phsaseClass[phase]} />
          </div>
        </div>
      </Col>
    );
  }
);
const PhaseCount = ({ setSelectedPhase, selectedPhase, setStartPage }) => {
  const dispatch = useDispatch();
  const { selectedLocation } = useSelector(({ role }) => role);
  const { phaseCount } = useSelector(({ dashboard }) => dashboard);

  useEffect(() => {
    dispatch(getPhaseCount(selectedLocation));
  }, []);

  return (
    <div>
      <Row className="home_card_partition pt-lg-0 pt-2 m-0">
        <Card
          count={phaseCount?.phase1Count}
          percent={phaseCount?.phase1Percent}
          thisWeekCount={phaseCount?.thisWeekPhase1Count}
          phase={1}
          setSelectedPhase={setSelectedPhase}
          selectedPhase={selectedPhase}
          setStartPage={setStartPage}
        />
        <Card
          count={phaseCount?.phase2Count}
          percent={phaseCount?.phase2Percent}
          thisWeekCount={phaseCount?.thisWeekPhase2Count}
          phase={2}
          setSelectedPhase={setSelectedPhase}
          selectedPhase={selectedPhase}
          setStartPage={setStartPage}
        />
        <Card
          count={phaseCount?.phase3Count}
          percent={phaseCount?.phase3Percent}
          thisWeekCount={phaseCount?.thisWeekPhase3Count}
          phase={3}
          setSelectedPhase={setSelectedPhase}
          selectedPhase={selectedPhase}
          setStartPage={setStartPage}
        />
        <Card
          count={phaseCount?.phase4Count}
          percent={phaseCount?.phase4Percent}
          thisWeekCount={phaseCount?.thisWeekPhase4Count}
          phase={4}
          setSelectedPhase={setSelectedPhase}
          selectedPhase={selectedPhase}
          setStartPage={setStartPage}
        />
      </Row>
    </div>
  );
};
export default memo(PhaseCount);
