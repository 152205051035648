import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";

const initialState = {
  patientListDetails: {},
  patientListLoading: false,
  patientListUpdate: false,
  isPatientDeleted: false,
};

export const getAllPatientListData = createAsyncThunk(
  "get-allPatientList-data",
  (props) => {
    return new Promise((resolve, reject) => {
      const { start, limit, payload } = props;
      axios
        .post(`admin/all-patient/list/${start}/${limit}`, payload)
        .then((res) => {
          if (res?.data?.err === 0) {
            if (Object.keys(res?.data?.data).length > 0) {
              resolve(res.data?.data);
            } else {
              resolve({});
            }
          } else {
            reject({});
          }
        })
        .catch((error) => {
          toast.error(error?.response?.data?.msg);
          reject(error);
        });
    });
  }
);

export const deleteUser = createAsyncThunk(
  "admin/delete-patient",
  (props, { dispatch }) => {
    return new Promise((resolve, reject) => {
      const { patient_reg_id } = props;
      axios
        .post(`admin/delete/patient/${patient_reg_id}`)
        .then((res) => {
          if (res?.data?.err === 0) {
            toast.success(res.data?.msg);
            resolve(res.data?.data);
          } else {
            toast.error(res?.data?.msg);
            reject({ message: res?.data?.msg });
          }
        })
        .catch((error) => {
          toast.error(error?.response?.data?.msg);
          reject(error);
        });
    });
  }
);

export const patientListDataSlice = createSlice({
  name: "patient-list-data",
  initialState,
  reducers: {
    setPatientListDetailsData: (state, action) => {
      state.patientListDetails = action.payload;
    },
    setIsPatientDeleted: (state, action) => {
      state.isPatientDeleted = action.payload;
    },
  },
  extraReducers: {
    [getAllPatientListData.pending]: (state) => {
      state.patientListDetails = {};
      state.patientListLoading = true;
    },
    [getAllPatientListData.rejected]: (state) => {
      state.patientListDetails = {};
      state.patientListLoading = false;
    },
    [getAllPatientListData.fulfilled]: (state, action) => {
      state.patientListDetails = action.payload;
      state.patientListLoading = false;
    },
    [deleteUser.pending]: (state) => {
      state.isPatientDeleted = false;
      state.patientListLoading = true;
    },
    [deleteUser.rejected]: (state) => {
      state.isPatientDeleted = false;
      state.patientListLoading = false;
    },
    [deleteUser.fulfilled]: (state) => {
      state.isPatientDeleted = true;
      state.patientListLoading = false;
    },
  },
});
export const { setPatientListDetailsData, setIsPatientDeleted } =
  patientListDataSlice.actions;
export default patientListDataSlice.reducer;
