import React, { memo } from "react";
import { Image } from "react-bootstrap";
import LogoImage from "../../../Img/user_logo_image.svg";
import ScheduleIcon from "../../../Img/pickup-icon.svg";
import { useDispatch, useSelector } from "react-redux";
import { setPatientEdit } from "redux/reducers/SearchPanel/globalSearch.slice";
import { sendDataToSocket } from "socket/SocketComponent";
import {
  getPermissionLocationWise,
  setSelectedLocation,
} from "redux/reducers/Role/role.slice";

const ScheduleList = () => {
  const { scheduleObject, scheduleLoader } = useSelector(
    ({ dashboard }) => dashboard
  );
  const dispatch = useDispatch();
  let UserPreferences = localStorage.getItem("UserPreferences");
  if (UserPreferences) {
    UserPreferences = JSON.parse(window.atob(UserPreferences));
  }
  if (scheduleLoader) {
    return (
      <div className="schedule_loading">
        <p>Loading...</p>
      </div>
    );
  }
  const openPatient = (record) => {
    dispatch(setPatientEdit(record));
    localStorage.setItem(
      "userLocation",
      window.btoa(JSON.stringify(record.location_id))
    );
    sendDataToSocket("JUR", {
      room_id: record.location_id,
    });
    dispatch(setSelectedLocation(record.location_id));
    dispatch(getPermissionLocationWise(record.location_id));
  };
  return (
    <>
      {scheduleObject?.scheduleList?.length ? (
        <>
          {scheduleObject?.scheduleList?.map((item) => {
            return (
              <div
                className="user_information d-flex justify-content-between align-content-center"
                onClick={() => openPatient(item)}
              >
                <div className="user_information_partioton">
                  <div className="d-flex">
                    <div className="user_logo d-flex justify-content-between align-items-center">
                      <div className="user_logo_image">
                        <Image
                          src={LogoImage}
                          alt=""
                          className="h-100 w-100 object-fit-cover"
                        />
                      </div>
                    </div>
                    <div className="user_description">
                      <p className="m-0 user_name">
                        {item.patient_full_name}{" "}
                        {!UserPreferences?.other && item.location_name
                          ? ` - ${item.location_name}`
                          : ""}
                      </p>
                      <div className="pickup_time d-flex align-items-center">
                        <div className="pickup_time_icon">
                          <Image
                            src={ScheduleIcon}
                            alt=""
                            className="h-100 w-100 object-fit-cover"
                          />
                        </div>
                        <p className="m-0 user_pickup_time">{item.activity}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="user_time d-flex align-items-center">
                  <p className="m-0">{item.pick_up_time}</p>
                </div>
              </div>
            );
          })}
        </>
      ) : (
        <>
          <div className="data-found d-flex align-items-center justify-content-center">
            <div className="no-data-fount">
              <h3>No data found</h3>
            </div>
          </div>
        </>
      )}
    </>
  );
};
export default memo(ScheduleList);
